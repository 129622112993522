import { ConfigProvider } from 'antd';
import { ThemeConfig } from 'antd/lib/config-provider/context';
import { IntlProvider } from 'react-intl';
import { SupportedLanguages } from 'utils/lang/SupportedLanguages';
import messages_en from 'utils/lang/en.json';
import messages_ro from 'utils/lang/ro.json';
import dayjs from 'dayjs';
import roRO from 'antd/locale/ro_RO';
import enGB from 'antd/locale/en_GB';
import updateLocale from 'dayjs/plugin/updateLocale';

import {
  createContext,
  useContext,
  useState,
  Dispatch,
  SetStateAction,
  useEffect
} from 'react';

dayjs.extend(updateLocale);
dayjs.updateLocale(SupportedLanguages.En, {
  weekStart: 1
});

export interface IThemeProviderContext {
  theme: 'light' | 'dark';
  setTheme: Dispatch<SetStateAction<'light' | 'dark'>>;
}

const useThemeProviderContext = () => {
  const [theme, setTheme] = useState<'light' | 'dark'>('light');

  function toggleTheme() {
    setTheme(theme === 'light' ? 'dark' : 'light');
  }

  function useForceUpdateTheme() {
    return () => setTheme(value => (value === 'light' ? 'dark' : 'light'));
  }

  return {
    theme,
    setTheme,
    toggleTheme,
    useForceUpdateTheme
  };
};

const ThemProviderContext = createContext(
  {} as ReturnType<typeof useThemeProviderContext>
);

export const useThemeContext = () => useContext(ThemProviderContext);

export const ThemeContextProvider = ({ children }) => {
  return (
    <ThemProviderContext.Provider value={useThemeProviderContext()}>
      {children}
    </ThemProviderContext.Provider>
  );
};

const useMobileProviderContext = () => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [isTabletDevice, setIsTabletDevice] = useState<boolean>(false);
  const [isSmallLaptop, setIsSmallLaptop] = useState<boolean>(false);
  const [deviceWidth, setDeviceWidth] = useState<number>(window.innerWidth);
  const [deviceHeight, setDeviceHeight] = useState<number>(window.innerHeight);
  const [deviceOS, setDeviceOS] = useState<string>(window.navigator.userAgent);

  useEffect(() => {
    const handleDeviceSize = () => {
      setDeviceWidth(window.innerWidth);
      setDeviceHeight(window.innerHeight);
    };
    window.addEventListener('resize', handleDeviceSize);

    setIsMobile(deviceWidth <= 768);
    setIsTabletDevice(deviceWidth > 768 && deviceWidth < 1024);
    setIsSmallLaptop(deviceWidth >= 1024 && deviceWidth <= 1440);

    return () => {
      window.removeEventListener('resize', handleDeviceSize);
    };
  }, [deviceWidth]);

  useEffect(() => {
    if (deviceOS.includes('iPhone')) {
      setDeviceOS('IOS');
    } else if (!deviceOS.includes('iPhone')) setDeviceOS('Android');
  }, []);

  return {
    isMobile,
    isTabletDevice,
    isSmallLaptop,
    deviceWidth,
    deviceHeight,
    deviceOS
  };
};

const MobileProviderContext = createContext(
  {} as ReturnType<typeof useMobileProviderContext>
);

export const useMobileContext = () => useContext(MobileProviderContext);

export const MobileContextProvider = ({ children }) => {
  return (
    <MobileProviderContext.Provider value={useMobileProviderContext()}>
      {children}
    </MobileProviderContext.Provider>
  );
};

const useIntlProviderContext = () => {
  const defaultLanguage = localStorage.getItem('defaultLanguage');
  const [locale, setLocale] = useState(defaultLanguage || 'en');

  useEffect(() => {
    setLocale(locale);
  });

  const messages = {
    en: messages_en,
    ro: messages_ro
  };

  return {
    locale,
    setLocale,
    messages
  };
};

const IntlProviderContext = createContext(
  {} as ReturnType<typeof useIntlProviderContext>
);

export const useIntlContext = () => useContext(IntlProviderContext);

export const CustomIntlContextProvider = ({ children }) => {
  return (
    <IntlProviderContext.Provider value={useIntlProviderContext()}>
      {children}
    </IntlProviderContext.Provider>
  );
};

export const IntlContextProvider = ({ children }) => {
  const { messages, locale } = useIntlContext();

  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      {children}
    </IntlProvider>
  );
};

export const ConfigProviderSettings = ({ children }) => {
  const { theme, setTheme } = useThemeContext();
  const [locale, setLocale] = useState(enGB);

  const intlLocale = useIntlContext().locale;

  useEffect(() => {
    if (localStorage.getItem('enableDarkTheme') !== 'Dark') {
      return;
    }

    setTheme('dark');
  });

  useEffect(() => {
    if (intlLocale === SupportedLanguages.Ro) {
      setLocale(roRO);
      dayjs.locale(SupportedLanguages.Ro);
    } else if (intlLocale === SupportedLanguages.En) {
      setLocale(enGB);
      dayjs.locale(SupportedLanguages.En);
    }
  }, [intlLocale]);

  return (
    <ConfigProvider
      theme={theme === 'light' ? lightTheme : darkTheme}
      locale={locale}
    >
      {children}
    </ConfigProvider>
  );
};

const defaultTokenProps = {
  borderRadius: 24,
  fontSize: 12,
  fontFamily: 'Montserrat'
};

const defaultButtonThemeProps = {
  borderRadius: 24,
  controlHeight: 28, //height Button
  lineHeight: 1.25,
  fontSize: 12,
  colorPrimary: 'rgba(64, 42, 135, 1)',
  colorPrimaryHover: 'rgba(64, 42, 135, 1)'
};

const defaultModalThemeProps = {
  fontSizeHeading5: 18,
  borderRadius: 20,
  fontWeightStrong: 500,
  lineHeightHeading5: 1.5
};

const defaultInputThemeProps = {
  borderRadius: 10,
  fontWeight: 500,
  colorBorder: '#BDA7E8'
};

const defaultDatePickerThemeProps = {
  ...defaultInputThemeProps
};

const defaultSelectThemeProps = {
  ...defaultInputThemeProps
};

const menuThemeProps = {
  fontSize: 16,
  lineHeight: 20
};

const cardThemeProps = {
  fontSize: 12,
  fontWeightStrong: 500,
  lineHeight: 1.25
};

const tableThemeProps = {
  colorTextHeading: '#BDA7E8',
  fontWeightStrong: 700,
  lineHeight: 1.25,
  fontSize: 14,
  borderRadius: 20,
  lineWidth: 0
};

export const lightTheme: ThemeConfig = {
  token: {
    colorBgLayout: '#EDECF8',
    colorBgContainer: '#EDECF8',
    colorBgElevated: '#EDECF8',
    colorTextBase: '#402A87',
    colorPrimary: '#402A87',
    colorPrimaryBg: 'rgba(64, 42, 135, 0.2)',
    ...defaultTokenProps
  },
  components: {
    Button: {
      colorTextDisabled: '#FFFFFF', //text disabled
      colorBgContainerDisabled: 'rgba(0, 0, 0, 0.1)', //bg disabled
      colorBgElevated: 'rgba(64, 42, 135, 1)',
      colorPrimaryBgHover: 'rgba(64, 42, 135, 0.2)',
      colorBorder: 'rgba(64, 42, 135, 1)', //border default btn
      colorText: 'rgba(64, 42, 135, 1)', //color text default btn
      boxShadowSecondary: 'rgba(64, 42, 135, 1) !important',
      ...defaultButtonThemeProps
    },
    Modal: {
      colorBgElevated: 'rgba(255, 255, 255, 0.8)',
      colorBgMask: '#EDECF8',
      boxShadow: '0 0 40 rgba(64, 42, 135, 0.4)',
      ...defaultModalThemeProps
    },
    Form: {
      colorTextHeading: '#BDA7E8',
      colorTextDisabled: 'rgba(0, 0, 0, 0.2)'
    },
    Input: {
      colorText: '#402A87',
      colorBgContainer: 'rgba(255, 255, 255, 0.6)',
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.4)',
      colorPrimaryHover: '#402A87',
      colorPrimaryActive: '#402A87',
      colorTextDisabled: 'rgba(0, 0, 0, 0.2)',
      ...defaultInputThemeProps
    },
    DatePicker: {
      colorBgContainer: 'rgba(255, 255, 255, 0.6)',
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.4)',
      colorBgElevated: 'rgba(255, 255, 255, 0.95)',
      colorTextDisabled: 'rgba(0, 0, 0, 0.2)',
      ...defaultDatePickerThemeProps,
      fontWeightStrong: 500
    },
    Select: {
      colorBgContainer: 'rgba(255, 255, 255, 0.6)',
      controlItemBgHover: 'rgba(189, 167, 232, 0.6)',
      controlItemBgActive: 'rgba(189, 167, 232, 0.6)',
      colorBgElevated: 'rgba(255, 255, 255, 0.95)',
      ...defaultSelectThemeProps
    },
    Menu: {
      colorItemTextSelected: 'rgba(64, 42, 135, 1)',
      colorItemText: 'rgba(64, 42, 135, 0.5)',
      colorItemBgSelected: 'rgba(255, 255, 255, 0.4)',
      colorItemBg: 'rgba(255, 255, 255, 0.8)',
      colorBgElevated: 'rgba(255, 255, 255, 0.8)',
      colorBgMask: 'rgba(255, 255, 255, 0.8)',
      ...menuThemeProps
    },
    Typography: {
      fontSizeHeading1: 32,
      fontSizeHeading2: 28,
      fontSizeHeading3: 20,
      fontSizeHeading4: 18,
      fontSizeHeading5: 16,
      fontWeightStrong: 700
    },
    Card: {
      boxShadow: '0px 0px 16px rgba(64, 42, 135, 0.2)',
      colorBgContainer: `rgba(255, 255, 255, 0.4)`,
      colorText: 'rgba(64, 42, 135, 1)',
      colorTextBase: 'rgba(64, 42, 135, 1)',
      ...cardThemeProps
    },
    Table: {
      boxShadow: '0px 0px 16px rgba(64, 42, 135, 0.2)',
      colorText: 'rgba(64, 42, 135, 1)',
      // colorBgContainer: 'rgba(255, 255, 255, 0.4)',
      ...tableThemeProps
    },
    Layout: {
      colorBgHeader: 'rgba(255, 255, 255, 0.8)'
    },
    Segmented: {
      colorTextLabel: 'rgba(64, 42, 135, 1)',
      colorText: 'rgba(64, 42, 135, 1)',
      colorBgLayout: 'rgba(255, 255, 255, 0.4)',
      colorBgElevated: 'rgba(255, 255, 255, 0.4)',
      boxShadow: '0px 0px 16px rgba(64, 42, 135, 0.2)',
      lineWidthBold: 0.5,
      lineWidth: 2,
      controlPaddingHorizontal: 11,
      controlPaddingHorizontalSM: 11,
      borderRadius: 16,
      borderRadiusLG: 16,
      borderRadiusSM: 16,
      borderRadiusXS: 16
    },
    // Switch: {
    //   colorPrimary:
    //     'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))',
    //   colorTextQuaternary:
    //     'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))', // unchecked
    //   colorTextTertiary:
    //     'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))', // hover unchecked
    //   colorPrimaryHover:
    //     'linear-gradient(rgba(255, 255, 255, 1), rgba(255, 255, 255, 0.4))' // hover checked
    // },
    Checkbox: {
      colorBgContainer:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
      colorPrimaryHover: 'rgba(64, 42, 135, 0.88)',
      colorPrimary: 'rgba(64, 42, 135)'
    }
  }
};

export const darkTheme: ThemeConfig = {
  token: {
    colorBgLayout: '#1D1B33',
    colorBgContainer: '#1D1B33',
    colorBgElevated: '#1D1B33',
    colorTextBase: '#BDA7E8',
    colorPrimary: '#fff',
    ...defaultTokenProps
  },
  components: {
    Button: {
      colorTextDisabled: 'rgba(255, 255, 255, 0.6)', //text disabled
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.2)', //bg disabled
      colorPrimaryBgHover: 'rgba(189, 167, 232, 0.2)',
      colorBorder: '#fff', //border default btn
      colorText: '#fff', //color text default btn
      ...defaultButtonThemeProps
    },
    Modal: {
      fontWeightStrong: 500,
      colorBgMask: '#1D1B33',
      colorBgElevated:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
      boxShadow: '0px 0px 16px rgba(189, 167, 232, 0.4)',
      ...defaultModalThemeProps
    },
    Form: {
      colorTextHeading: '#BDA7E8' // for labels of the items
    },
    Input: {
      colorText: '#fff',
      colorBgContainer: 'rgba(255, 255, 255, 0.2)',
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.2)',
      colorPrimaryHover: '#fff',
      colorPrimaryActive: '#fff',
      ...defaultInputThemeProps
    },
    DatePicker: {
      colorBorder: '#BDA7E8',
      colorBgContainer: 'rgba(255, 255, 255, 0.2)',
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.2)',
      colorBgElevated: 'rgba(255, 255, 255, 0.4)',
      ...defaultDatePickerThemeProps,
      fontWeightStrong: 500
    },
    Select: {
      colorBgContainer: 'rgba(255, 255, 255, 0.2)',
      colorBgElevated: 'rgba(255, 255, 255, 0.4)',
      colorBgContainerDisabled: 'rgba(255, 255, 255, 0.2)',
      controlItemBgHover: 'rgba(189, 167, 232, 0.6)',
      controlItemBgActive: 'rgba(189, 167, 232, 0.6)',
      ...defaultSelectThemeProps
    },
    Menu: {
      colorItemTextSelected: 'white',
      colorItemText: 'rgba(255, 255, 255, 0.5)',
      colorItemBgSelected: 'rgba(255, 255, 255, 0.4)',
      colorItemBg:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.2) 100%)',
      boxShadow: '0 0 16px rgb(189 167 232 / 40%)',
      colorBgBase:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
      ...menuThemeProps
    },
    Typography: {
      fontSizeHeading1: 32,
      fontSizeHeading2: 28,
      fontSizeHeading3: 20,
      fontSizeHeading4: 18,
      fontSizeHeading5: 16,
      fontWeightStrong: 700,
      colorText: '#fff',
      colorTextHeading: '#fff'
    },
    Card: {
      colorBgContainer: `linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)`,
      boxShadow: '0px 0px 16px rgba(189, 167, 232, 0.4)',
      colorText: 'rgba(189, 167, 232, 1)',
      ...cardThemeProps
    },
    Table: {
      boxShadow: '0px 0px 16px rgba(189, 167, 232, 0.4)',
      colorText: '#fff',
      ...tableThemeProps
    },
    Layout: {
      colorBgHeader:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)'
    },
    Segmented: {
      colorTextLabel: 'rgba(64, 42, 135, 1)',
      colorText: 'rgba(64, 42, 135, 1)',
      colorBgLayout: 'rgba(255, 255, 255, 0.2)',
      colorBgElevated: 'rgba(255, 255, 255, 0.4)',
      lineWidthBold: 0.5,
      lineWidth: 2,
      controlPaddingHorizontal: 11,
      controlPaddingHorizontalSM: 11,
      borderRadius: 16,
      borderRadiusLG: 16,
      borderRadiusSM: 16,
      borderRadiusXS: 16,
      boxShadow: ' 0px 0px 16px rgba(64, 42, 135, 0.4)'
    },
    Switch: {
      colorPrimary:
        ' linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
      colorTextQuaternary:
        ' linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)', // unchecked
      colorTextTertiary:
        ' linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)', // hover unchecked
      colorPrimaryHover:
        ' linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)' // hover checked
    },
    Dropdown: {
      controlItemBgActive: 'rgba(189, 167, 232, 0.6)',
      controlItemBgActiveHover: 'rgba(189, 167, 232, 0.6)',
      colorPrimary: 'rgb(64, 42, 135)'
    },
    Checkbox: {
      colorBgContainer:
        'linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.1) 100%)',
      colorPrimaryHover: 'rgba(64, 42, 135, 0.88)',
      colorPrimary: 'rgba(64, 42, 135, 0.88)'
    }
  }
};
