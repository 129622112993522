import {
  useEffect,
  useState,
  FC,
  useMemo,
  Dispatch,
  SetStateAction
} from 'react';
// import { DocumentBaseFields } from './utils';
import {
  Form,
  Input,
  Select,
  Upload,
  Button,
  DatePicker,
  Spin,
  Row,
  Col,
  UploadFile
} from 'antd';
import { useThemeContext } from 'AppProvider/ConfigProviderSettings';
import ColFormItemComponent from 'components/UIComponents/Form/ColComponent';
import { useMobileContext } from 'AppProvider/ConfigProviderSettings';
import {
  notifyWithError,
  notifyWithErrorOnMaxSizeUploadFile,
  notifyWithSuccessfulDataUpdated
} from 'utils/notificationsUtils';
import { EmployeeDocumentService } from 'services';
import { getFileUploadFormData } from 'utils/uploadFileRequestWithFormData';
import { FormattedMessage, useIntl } from 'react-intl';
import { DATE_FIELDS_ARR } from 'components/Employee/EmployeeDocumentsList/utils';
import dayjs from 'dayjs';
import {
  EmployeeDocumentSubType,
  EmployeeDocumentType
} from 'services/providers/EmployeeDocumentProvider/utils';
import './employeeDocumentsForm.less';
import { SelectDropdown } from 'components/Dropdown';
import { BaseButton, ButtonSimple } from 'components/Buttons';
import AttachmentItem from 'components/UIComponents/AttachmentItem';
import ComponentWithStatus from 'components/ComponentWithStatus';
import { DocumentFormPlaceholder } from 'components/Placeholders';
import { setFormItemRules } from 'components/UIComponents/Form/ColComponent/utils';
import { DocumentStatuses, DocumentSubTypes, DocumentTypes } from './utils';
import { validateFileType } from 'components/UploadFile/utils';
const { Option } = Select;
const { TextArea } = Input;

type EmployeeDocumentsFormProps = {
  editDocumentId?: string | number | null;
  employeeId: string | number | null;
  documentType?: EmployeeDocumentType | string | null;
  onSubmit?: () => void;
  setLoadStatus?: Dispatch<SetStateAction<boolean>>;
};

/**If @editDocumentId  is passed, the onSubmit method of the form performs calls to updateEmployeeDocument
 * If @editDocumentId  is null, the onSubmit method calls addEmployeeDocument
 *
 * @documentType, when form is rendered, the relevant fields will be rendered for the provided documentType, or will revert to default value
 */
const EmployeeDocumentsForm: FC<EmployeeDocumentsFormProps> = ({
  editDocumentId = null,
  employeeId,
  documentType = EmployeeDocumentType.IdentityDocument,
  onSubmit = () => {},
  setLoadStatus
}) => {
  const { theme } = useThemeContext();
  const intl = useIntl();

  const [selectedDocumentType, setSelectedDocumentType] =
    useState<EmployeeDocumentType>(documentType as EmployeeDocumentType);

  const { isMobile } = useMobileContext();
  const defaultSelectedSubtypesPerDocumentType = {
    [EmployeeDocumentType.IdentityDocument]:
      EmployeeDocumentSubType.IdentificationDocument,
    [EmployeeDocumentType.Diploma]:
      EmployeeDocumentSubType.BaccalaureateDiploma,
    [EmployeeDocumentType.Agreement]: EmployeeDocumentSubType.WorkAgreement,
    [EmployeeDocumentType.Others]: EmployeeDocumentSubType.Other
  };
  const [selectedDocumentSubType, setSelectedDocumentSubType] =
    useState<EmployeeDocumentSubType>(
      defaultSelectedSubtypesPerDocumentType[selectedDocumentType]
    );

  const [isLoaded, setIsLoaded] = useState<boolean>(
    editDocumentId ? false : true
  );
  const [fileList, setFileList] = useState<any>([]);
  const [isFileRequired] = useState<boolean>(false);
  const maxFilesPerRequest = 1;
  const [form] = Form.useForm();

  //upload file extension
  const acceptFilesExtension =
    'image/jpeg, image/jpg, image/png, image/gif, image/bmp, image/webp, image/svg+xml, application/pdf';

  //when selecting a document type in creation scope, always reset the previously selected subtype to a default
  useEffect(() => {
    if (editDocumentId === null) {
      setSelectedDocumentSubType(
        defaultSelectedSubtypesPerDocumentType[selectedDocumentType]
      );

      form.setFieldValue('documentType', selectedDocumentType);
      form.setFieldValue(
        'documentSubType',
        defaultSelectedSubtypesPerDocumentType[selectedDocumentType]
      );
    }
  }, [selectedDocumentType]);

  //load document values if editDocumentId is provided (form is editable)
  useEffect(() => {
    if (!!editDocumentId) {
      EmployeeDocumentService.getEmployeeDocuments(
        editDocumentId as string
      ).then(res => {
        Object.entries(res.data).forEach(([key, value]) => {
          form.setFieldValue(
            key,
            DATE_FIELDS_ARR.includes(key)
              ? dayjs(value).isValid()
                ? dayjs(value)
                : null
              : value
          );
        });

        setSelectedDocumentType(EmployeeDocumentType[res.data.documentType]);
        setSelectedDocumentSubType(
          EmployeeDocumentSubType[res.data.documentSubType]
        );
        setLoadStatus(true);
        setIsLoaded(true);
      });
    }
  }, [editDocumentId, form]);

  const renderAdditionalFields = useMemo(() => {
    switch (selectedDocumentType) {
      case EmployeeDocumentType.IdentityDocument: {
        switch (selectedDocumentSubType) {
          case EmployeeDocumentSubType.IdentificationDocument:
            return (
              <>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="personalId" />}
                  name="personalId"
                  rules={setFormItemRules({
                    requiredMessage: intl.formatMessage({
                      id: 'EmployeePersonalIDInputValidation'
                    }),
                    hasPattern: true,
                    isRequired: true,
                    pattern: /^[0-9]*$/,
                    patternMessage: intl.formatMessage({
                      id: 'PleaseInsertOnlyNumbersValidation'
                    }),
                    isSpecificLengthRequired: true,
                    requiredLength: 13,
                    specificLengthRequiredMessage: intl.formatMessage({
                      id: 'PersonalIDLengthValidationMessage'
                    })
                  })}
                >
                  <Input maxLength={13} />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="birthDate" />}
                  name="birthDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="birthDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    popupClassName={theme}
                  />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="addressOfResidence" />}
                  name="addressOfResidence"
                  rules={[
                    {
                      required: false,
                      max: 100
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="documentSeries" />}
                  name="documentSeries"
                  rules={[
                    {
                      required: false,
                      max: 20
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issuedBy" />}
                  name="issuedBy"
                  rules={[
                    {
                      required: false,
                      max: 50
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issueDate" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    popupClassName={theme}
                  />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="expiryDate" />}
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="expiryDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker
                    style={{ width: '100%' }}
                    popupClassName={theme}
                  />
                </ColFormItemComponent>
              </>
            );
          case EmployeeDocumentSubType.Passport:
            return (
              <>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="nationality" />}
                  name="nationality"
                  rules={[
                    {
                      required: true,
                      max: 20,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="nationality" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="birthDate" />}
                  name="birthDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="birthDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="documentSeries" />}
                  name="documentSeries"
                  rules={[
                    {
                      required: true,
                      max: 20,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="documentSeries" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="countryIssuer" />}
                  name="countryIssuer"
                  rules={[
                    {
                      required: true,
                      max: 20,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="countryIssuer" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issueDate" />}
                  name="issueDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="issueDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="expiryDate" />}
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="expiryDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>
              </>
            );
          case EmployeeDocumentSubType.DriversLicense:
            return (
              <>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="licenseCategory" />}
                  name="licenseCategory"
                  rules={[
                    {
                      required: true,
                      max: 10,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="licenseCategory" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="documentSeries" />}
                  name="documentSeries"
                  rules={[
                    {
                      required: true,
                      max: 20,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="documentSeries" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="driverLicenseId" />}
                  name="driverLicenseId"
                  rules={[
                    {
                      required: false,
                      max: 20
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issuedBy" />}
                  name="issuedBy"
                  rules={[
                    {
                      required: false,
                      max: 50
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issueDate" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="expiryDate" />}
                  name="expiryDate"
                  rules={[
                    {
                      required: false,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="expiryDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>
              </>
            );
          case EmployeeDocumentSubType.BirthCertificate:
            return (
              <>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="personalId" />}
                  name="personalId"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="personalId" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <Input pattern="^(\d{13})?$" />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="birthDate" />}
                  name="birthDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="birthDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="placeOfBirth" />}
                  name="placeOfBirth"
                  rules={[
                    {
                      required: false,
                      max: 50
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="documentSeries" />}
                  name="documentSeries"
                  rules={[
                    {
                      required: false,
                      max: 20
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issuedBy" />}
                  name="issuedBy"
                  rules={[
                    {
                      required: false,
                      max: 50
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>

                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issueDate" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>
              </>
            );
          case EmployeeDocumentSubType.Other:
            return (
              <>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="documentSeries" />}
                  name="documentSeries"
                  rules={[
                    {
                      required: false,
                      max: 20
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issuedBy" />}
                  name="issuedBy"
                  rules={[
                    {
                      required: false,
                      max: 50
                    }
                  ]}
                >
                  <Input />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="issueDate" />}
                  name="issueDate"
                  rules={[
                    {
                      required: false
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>
                <ColFormItemComponent
                  span={24}
                  label={<FormattedMessage id="expiryDate" />}
                  name="expiryDate"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="ValidationFormDocumentsNotProvided"
                          values={{
                            fieldName: <FormattedMessage id="expiryDate" />
                          }}
                        />
                      )
                    }
                  ]}
                >
                  <DatePicker popupClassName={theme} />
                </ColFormItemComponent>
              </>
            );
        }
        break;
      }
      case EmployeeDocumentType.Diploma: {
        return (
          <>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="institutionName" />}
              name="institutionName"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="ValidationFormDocumentsNotProvided"
                      values={{
                        fieldName: <FormattedMessage id="institutionName" />
                      }}
                    />
                  ),
                  max: 50
                }
              ]}
            >
              <Input />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="specialization" />}
              name="specialization"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="ValidationFormDocumentsNotProvided"
                      values={{
                        fieldName: <FormattedMessage id="specialization" />
                      }}
                    />
                  ),
                  max: 50
                }
              ]}
            >
              <Input />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="studyPeriodFrom" />}
              name="studyPeriodFrom"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="ValidationFormDocumentsNotProvided"
                      values={{
                        fieldName: <FormattedMessage id="studyPeriodFrom" />
                      }}
                    />
                  )
                }
              ]}
            >
              <DatePicker
                style={{ width: '100%', marginBottom: '5px' }}
                format="DD/MM/YYYY"
                inputReadOnly={true}
                popupClassName={theme}
              />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="studyPeriodTo" />}
              name="studyPeriodTo"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage
                      id="ValidationFormDocumentsNotProvided"
                      values={{
                        fieldName: <FormattedMessage id="studyPeriodTo" />
                      }}
                    />
                  )
                }
              ]}
            >
              <DatePicker
                style={{ width: '100%', marginBottom: '5px' }}
                format="DD/MM/YYYY"
                inputReadOnly={true}
                popupClassName={theme}
              />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="documentSeries" />}
              name="documentSeries"
              rules={[
                {
                  required: false,
                  max: 20
                }
              ]}
            >
              <Input />
            </ColFormItemComponent>

            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="issuedBy" />}
              name="issuedBy"
              rules={[
                {
                  required: false,
                  max: 50
                }
              ]}
            >
              <Input />
            </ColFormItemComponent>

            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="issueDate" />}
              name="issueDate"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>

            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="expiryDate" />}
              name="expiryDate"
              rules={[
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="ValidationFormDocumentsNotProvided"
                      values={{
                        fieldName: <FormattedMessage id="expiryDate" />
                      }}
                    />
                  )
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>
          </>
        );
      }
      case EmployeeDocumentType.Agreement:
        return (
          <>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="signedOn" />}
              name="signedOn"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="validFrom" />}
              name="validFrom"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="validTo" />}
              name="validTo"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>
          </>
        );
      case EmployeeDocumentType.TimeOffDocument:
        return <></>;
      case EmployeeDocumentType.Others:
        return (
          <>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="documentSeries" />}
              name="documentSeries"
              rules={[
                {
                  required: false,
                  max: 20
                }
              ]}
            >
              <Input />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="issuedBy" />}
              name="issuedBy"
              rules={[
                {
                  required: false,
                  max: 50
                }
              ]}
            >
              <Input />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="issueDate" />}
              name="issueDate"
              rules={[
                {
                  required: false
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>
            <ColFormItemComponent
              span={24}
              label={<FormattedMessage id="expiryDate" />}
              name="expiryDate"
              rules={[
                {
                  required: false,
                  message: (
                    <FormattedMessage
                      id="ValidationFormDocumentsNotProvided"
                      values={{
                        fieldName: <FormattedMessage id="expiryDate" />
                      }}
                    />
                  )
                }
              ]}
            >
              <DatePicker popupClassName={theme} />
            </ColFormItemComponent>
          </>
        );

      default:
        <div>No fields..</div>;
    }
  }, [selectedDocumentType, selectedDocumentSubType, isMobile, editDocumentId]);

  const ButtonCustom = BaseButton(ButtonSimple);

  const onFileRemove = (file: UploadFile<any>) => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };
  return (
    <ComponentWithStatus
      status={isLoaded ? 'success' : 'pending'}
      Placeholder={<DocumentFormPlaceholder />}
    >
      <Form
        form={form}
        id="documentsForm"
        layout="horizontal"
        size="middle"
        labelAlign="left"
        labelWrap
        labelCol={{ span: 8 }}
        onFinish={formData => {
          formData['employeeId'] = employeeId;

          if (editDocumentId) {
            formData['employeeDocumentId'] = editDocumentId;
          }

          const parsedData = getFileUploadFormData(
            {
              employeeDocumentContent: formData
            },
            fileList.length > 0
              ? {
                  formDataFileName: 'file',
                  uploadFiles: fileList
                }
              : null
          );

          if (editDocumentId) {
            EmployeeDocumentService.updateEmployeeDocument(parsedData).then(
              res => {
                if (!res.error) {
                  notifyWithSuccessfulDataUpdated(
                    <FormattedMessage id="DocumentUpdatedSuccessfully" />
                  );
                  onSubmit();
                }
              }
            );
          } else {
            EmployeeDocumentService.addEmployeeDocument(parsedData).then(
              res => {
                if (!res.error) {
                  notifyWithSuccessfulDataUpdated(
                    <FormattedMessage id="DocumentAddedSuccessfully" />
                  );
                  onSubmit();
                }
              }
            );
          }
        }}
        onFinishFailed={formData => {
          console.log(formData);
        }}
      >
        {/* FIELD: Document Type */}

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="documentType" />}
          name="documentType"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="ValidationFormDocumentsNotProvided"
                  values={{
                    fieldName: <FormattedMessage id="documentType" />
                  }}
                />
              )
            }
          ]}
        >
          <SelectDropdown
            placement_bottom={true}
            disabled={!!editDocumentId}
            onChange={value => setSelectedDocumentType(value)}
          >
            {DocumentTypes.map((documentType, index) => (
              <Option key={documentType} value={documentType}>
                {intl.formatMessage({
                  id: documentType
                })}
              </Option>
            ))}
          </SelectDropdown>
        </ColFormItemComponent>

        {/* FIELD: Document Sub Type */}

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="documentSubType" />}
          name="documentSubType"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="ValidationFormDocumentsNotProvided"
                  values={{
                    fieldName: <FormattedMessage id="documentSubType" />
                  }}
                />
              )
            }
          ]}
        >
          <SelectDropdown
            placement_bottom={true}
            disabled={!!editDocumentId}
            onChange={value => setSelectedDocumentSubType(value)}
          >
            {DocumentSubTypes[
              selectedDocumentType &&
              selectedDocumentType !== EmployeeDocumentType.TimeOffDocument
                ? selectedDocumentType
                : EmployeeDocumentType.Others
            ].map(subType => (
              <Option key={subType} value={subType}>
                {intl.formatMessage({
                  id: subType
                })}
              </Option>
            ))}
          </SelectDropdown>
        </ColFormItemComponent>

        {/* FIELD: Document Name */}

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="documentName" />}
          name="documentName"
          rules={[
            {
              max: 50,
              message: (
                <FormattedMessage
                  id="alidationFormDocumentsProvidedShort"
                  values={{
                    fieldName: <FormattedMessage id="documentName" />
                  }}
                />
              )
            }
          ]}
        >
          <Input />
        </ColFormItemComponent>

        {/* FIELD: Description */}

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="description" />}
          name="description"
          rules={[
            {
              max: 500,
              message: (
                <FormattedMessage
                  id="alidationFormDocumentsProvidedShort"
                  values={{
                    fieldName: <FormattedMessage id="description" />
                  }}
                />
              )
            }
          ]}
        >
          <TextArea maxLength={500} />
        </ColFormItemComponent>

        {/* FIELD: Document Status */}

        <ColFormItemComponent
          span={24}
          label={<FormattedMessage id="documentStatus" />}
          name="documentStatus"
          rules={[
            {
              required: true,
              message: (
                <FormattedMessage
                  id="ValidationFormDocumentsNotProvided"
                  values={{
                    fieldName: <FormattedMessage id="documentStatus" />
                  }}
                />
              )
            }
          ]}
        >
          <SelectDropdown
            placement_bottom={true}
            dropdownStyle={{ animationDuration: '0s' }}
          >
            {DocumentStatuses.map(status => (
              <Option key={status} value={status}>
                {intl.formatMessage({
                  id: status
                })}
              </Option>
            ))}
          </SelectDropdown>
        </ColFormItemComponent>

        {/* ADDITIONAL FIELD */}

        {renderAdditionalFields}

        {/* FIELD: Upload File */}

        <ColFormItemComponent
          span={24}
          name="uploadFile"
          wrapperCol={{ span: 24 }}
          rules={[
            {
              validator(_, value) {
                if (isFileRequired && !value && !editDocumentId) {
                  return Promise.reject(
                    new Error(
                      intl.formatMessage({
                        id: 'CertificateInputValidation'
                      })
                    )
                  );
                }

                return Promise.resolve();
              }
            }
          ]}
        >
          <Upload
            multiple={false}
            fileList={fileList}
            accept={acceptFilesExtension}
            maxCount={maxFilesPerRequest}
            beforeUpload={uploadedFile => {
              const isAllowedType = validateFileType(
                uploadedFile,
                acceptFilesExtension
              );
              if (!isAllowedType) {
                notifyWithError({
                  message: (
                    <FormattedMessage id="upload_file_type_not_allowed" />
                  ),
                  description: null
                });
                form.validateFields(['uploadFile']);
                return false;
              }
              if (fileList && fileList.length > maxFilesPerRequest - 1) {
                notifyWithError({
                  message: (
                    <FormattedMessage
                      id="MaximumFilesToUploadErrorMessage"
                      values={{ maxFilesPerRequest: maxFilesPerRequest }}
                    />
                  ),
                  description: null
                });
                form.validateFields(['uploadFile']);
                return false;
              }
              if (uploadedFile.size && uploadedFile.size >= 5 * 1000000) {
                notifyWithErrorOnMaxSizeUploadFile(5);
                form.validateFields(['uploadFile']);
                return false;
              }
              setFileList([...fileList, uploadedFile]);
              return false;
            }}
            onRemove={onFileRemove}
            itemRender={(originNode, file) => (
              <AttachmentItem
                file={file}
                onRemoveButtonPress={() => onFileRemove(file)}
              />
            )}
          >
            <ButtonCustom
              className={`text-bold-normal btn-upload-custom  ${theme}`}
              style={{
                padding: '4px 8px'
              }}
            >
              <span>
                <FormattedMessage
                  id={!editDocumentId ? 'Upload' : 'UploadOtherDocument'}
                />
              </span>
            </ButtonCustom>
          </Upload>
        </ColFormItemComponent>
      </Form>
    </ComponentWithStatus>
  );
};
export default EmployeeDocumentsForm;
